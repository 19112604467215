import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import RealmService from "./RealmService";
import CredentialsAPI from "../Credentials/CredentialsAPI";
import {useParams} from "react-router-dom";
import BarChart from "./BarChart";
import Table from "./Table";
import {format} from 'date-fns';
import TableTeams from "./TableTeams";
import BarChartUCI from "./BarChartUCI";
import BarChartRaces from "./BarChartRaces";
import BarChartMarketValue from "../TeamDetails/BarChartMarketValue";
import RadarChart from "./RadarChart";
import TableResults from "./TableResults";
import Arrow from "./Arrow";
import BarChartRanking from "./BarChartRanking";
import BarChartDistance from "./BarChartDistance";
import TableSimilar from "./TableSimilar";
import ReactCountryFlag from "react-country-flag";
import {Helmet} from "react-helmet";
import TablePredicted from "./TablePredicted";


const auctionsService = new RealmService('cycling-trbvl');

const RiderDetails = () => {
    const {name} = useParams();
    const [queryResult, setQueryResult] = useState([]);
    const [queryResult2, setQueryResult2] = useState([]);
    const [queryResult3, setQueryResult3] = useState([]);
    const [queryResult4, setQueryResult4] = useState([]);
    const [details, setDetails] = useState([]);
    const [queryResult5, setQueryResult5] = useState([]);
    const [queryResult6, setQueryResult6] = useState([]);
    const [queryResult7, setQueryResult7] = useState([]);
    const [maxValue, setMaxValue] = useState([]);
    const [similarRiders, setSimilarRiders] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            await auctionsService.initialize('cycling-trbvl', CredentialsAPI.API_CYCLING);

            const mongodb = await auctionsService.getMongoClient();
            if (mongodb) {
                const collection = mongodb.db('Cyclists').collection('Cyclists');
                const collection_predicted = mongodb.db('Cyclists').collection('Predicted_Stats');

                // Perform queries or operations on the collection

                // Example: Fetch all documents from the collection
                const documents = await collection.find({name: name});


                const rank_per_season = documents[0].historic


                if (documents) {
                    const birthdate = new Date(documents[0].birthdate);
                    const formattedBirthdate = format(birthdate, 'MMMM d, yyyy');
                    const birthdateObj = new Date(birthdate);
                    const today = new Date();
                    let age = today.getFullYear() - birthdateObj.getFullYear();

                    // Check if the birthdate hasn't occurred yet this year
                    const hasBirthdayPassed = today.getMonth() > birthdateObj.getMonth() ||
                        (today.getMonth() === birthdateObj.getMonth() && today.getDate() >= birthdateObj.getDate());
                    if (!hasBirthdayPassed) {
                        age--;
                    }

                    const predicted = await collection_predicted.find({_id: documents[0]._id})
                    console.log("predicted")
                    console.log(predicted)
                    if (predicted && predicted[0]) {
                        setQueryResult7(predicted[0])
                    }


                    setDetails({
                        birthdate: formattedBirthdate,
                        name: documents[0].name,
                        height: documents[0].height,
                        weight: documents[0].weight,
                        country: documents[0].country,
                        team: documents[0].team,
                        count_years: documents[0].count_years,
                        position: documents[0].position,
                        market_value: documents[0].market_value,
                        profile_link: documents[0].profile_link,
                        age: age,
                        points: documents[0].points

                    });
                }


                const points_per_season = documents[0].historic.reverse().map((item) => {
                    return {
                        "season": item.year,
                        "points": item.points,
                        "distance": item.distance,
                        "points_per_distance": item.points_per_distance,
                        "wins_per_race": item.wins_per_race,
                        "wins": item.wins,
                        "races": item.races,
                        "ranking": item.ranking
                    }
                });

                let results = []; // Assuming documents is an array containing the necessary data

                if (documents[0]?.results != null) {

                    results = documents[0].results.reverse().map((item) => {
                        const date = new Date(item.Date);
                        const formattedDate = date.toLocaleDateString('en-GB'); // 'en-GB' represents the format 'dd/mm/yyyy'

                        return {
                            "Datetime": date,
                            "Date": formattedDate,
                            "Pos": item.Pos,
                            "Race": item.Race
                        };
                    }).sort((a, b) => new Date(b.Datetime) - new Date(a.Datetime));
                } else {
                    console.log('Results is null or undefined.');
                }


                let teams_history = []; // Assuming documents is an array containing the necessary data

                if (documents[0]?.teams_history != null) {
                    teams_history = documents[0].teams_history.reverse().map((item) => {
                        return {
                            "season": item.season,
                            "team": item.team_name,
                            "class": item.class
                        }
                    });
                } else {
                    console.log('teams_history is null or undefined.');
                }

                let modes = []; // Assuming documents is an array containing the necessary data
                let modes_2
                if (documents[0]?.modes != null) {
                    modes = documents[0].modes
                    modes_2 = {...modes}; // or modes_2 = Object.assign({}, modes);
                }

                const maxValue = Math.max(...Object.values(modes));

                // Find the key corresponding to the maximum value
                const maxKey = Object.keys(modes).find((key) => modes[key] === maxValue);
                setMaxValue(maxKey);

                delete modes_2[maxKey]

// Now find the second maxKey after removing the first occurrence
                const secondMaxKey = Object.keys(modes_2).find((key) => modes_2[key] === Math.max(...Object.values(modes_2)));


                const mode_type = "modes." + maxKey
                const mode_type2 = "modes." + secondMaxKey


                const query = {
                    $and: [
                        {[mode_type]: {$gte: documents[0].modes[maxKey] - 5}},
                        {[mode_type]: {$lte: documents[0].modes[maxKey] + 5}},
                        {[mode_type2]: {$gte: documents[0].modes[secondMaxKey] - 5}},
                        {[mode_type2]: {$lte: documents[0].modes[secondMaxKey] + 5}},
                        {name: {$ne: (name)}}
                    ],
                };

                const similar_cyclists = await collection.aggregate([
                    {$match: query}, // Your initial query (optional)
                    {$sample: {size: 5}}
                ])
                setSimilarRiders(similar_cyclists)

                let market_value_history = []; // Assuming documents is an array containing the necessary data

                if (documents[0]?.market_value_history != null) {
                    market_value_history = documents[0].market_value_history.map((item) => {
                        const dateObj = new Date(item.date);
                        const formattedDate = dateObj.toLocaleDateString('en-GB'); // Use 'en-GB' for dd/mm/yyyy format, adjust to your locale if needed

                        return {
                            "date": formattedDate,
                            "value": item.value,
                        };
                    });
                } else {
                    console.log('market_value_history is null or undefined.');
                }


                setQueryResult(points_per_season);
                setQueryResult2(rank_per_season);
                setQueryResult3(teams_history);
                setQueryResult4(market_value_history);
                setQueryResult5(modes);
                setQueryResult6(results);
            }
        };

        fetchData();
    }, []);


    return (
        <Container fluid className="project-section">
            <Helmet>
                <title>Rider Details: {name}</title>
                <meta name="description" content={`Details for the rider ${name}`}/>

                {/* Open Graph (OG) meta tags */}
                <meta property="og:url" content="https://www.cycling.mrfreire.net/riders/"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={`Details for the rider ${name}`}/>
                <meta property="og:description" content={`Details and market value for the rider ${name}`}/>
            </Helmet>
            <Container><h1 className="project-heading">
                <strong>{details.name}</strong> <ReactCountryFlag countryCode={retrieveCountryCode(details.country)}/>
            </h1>

                <Row style={{justifyContent: "center", padding: "20px"}}>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Birthdate</h6>
                            <strong>{details.birthdate}</strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Age</h6>
                            <strong>{details.age}</strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Height</h6>
                            <strong>{details.height} m</strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Weight</h6>
                            <strong>{details.weight} kg</strong>
                        </div>
                    </Col>


                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Country</h6>
                            <strong><a href={"/country/" + details.country}>{details.country}</a></strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Position</h6>
                            <strong>{details.position}</strong>
                        </div>
                    </Col>
                </Row>

                <Row style={{justifyContent: "center", padding: "20px"}}>


                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Team</h6>
                            <strong><a href={"/team/" + details.team}>{details.team}</a></strong>
                        </div>
                    </Col>
                    <Col>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Years Professional</h6>
                            <strong> {details.count_years}</strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Points</h6>
                            {details.points ? details.points : ''}
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Type</h6>
                            <strong>{maxValue}</strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Market Value</h6>
                            <strong>{details.market_value ? details.market_value.toLocaleString() + ' €' : ''}</strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Trend</h6>

                            <strong><Arrow market_value_history={queryResult4}/></strong>
                        </div>
                    </Col>
                </Row>

                <Container>
                    <h4 className="project-heading-2">
                        <strong>Historic Results</strong>
                    </h4>

                </Container>
                <Container>

                    <Row style={{justifyContent: "center", padding: "20px"}}>
                        <Col md={8} sm={8}>
                            <div style={{overflow: "auto", alignContent: "center"}}>
                                <Table
                                    data={queryResult2}
                                    className="custom-table"
                                    style={{color: "white", padding: "20px"}}
                                />
                            </div>
                        </Col>

                        <Col md={4} sm={4}>
                            <div style={{overflow: "auto", alignContent: "center"}}>
                                <RadarChart
                                    data={queryResult5}
                                    style={{color: "white", padding: "20px"}}
                                />
                            </div>
                        </Col>

                    </Row>
                </Container>
                <h2 className="project-heading-2">
                    <strong>Statistics</strong>
                </h2>
                <Container>
                    <Container>
                        <Row style={{justifyContent: "center", padding: "20px"}}>
                            <Col md={4} sm={4}>
                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChart
                                        data={queryResult}
                                        className="custom-table"
                                        style={{color: "white", padding: "40px"}}
                                    />
                                </div>
                            </Col>

                            <Col md={4} sm={4}>
                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChartUCI
                                        data={queryResult}
                                        className="custom-table"
                                        style={{color: "white", padding: "20px"}}
                                    />
                                </div>
                            </Col>


                            <Col md={4} sm={4}>


                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChartRanking
                                        data={queryResult}
                                        className="custom-table"
                                        style={{color: "white", padding: "20px"}}
                                    />
                                </div>
                            </Col>
                            <p>
                            </p>

                            <Col md={4} sm={4}>


                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChartDistance
                                        data={queryResult}
                                        className="custom-table"
                                        style={{color: "white", padding: "20px"}}
                                    />
                                </div>
                            </Col>
                            <Col md={4} sm={4}>
                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChartRaces
                                        data={queryResult}
                                        className="custom-table"
                                        style={{color: "white", padding: "40px"}}
                                    />
                                </div>
                            </Col>
                            <Col md={4} sm={4}>
                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChartMarketValue
                                        data={queryResult4}
                                        className="custom-table"
                                        style={{color: "white", padding: "20px"}}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container>
                    <h4 className="project-heading-2">
                        <strong>Other data</strong>
                    </h4>
                    <Row style={{justifyContent: "center", padding: "20px"}}>


                        <Col md={6} sm={6}>
                            <h4 className="project-heading-2">
                                <strong>Predicted Results</strong>
                            </h4>
                            <div style={{overflow: "auto", alignContent: "center"}}>
                                <TablePredicted
                                    data={queryResult7}
                                    className="custom-table"
                                    style={{color: "white", padding: "20px"}}
                                />
                            </div>
                        </Col>


                        <Col md={6} sm={6}>
                            <h4 className="project-heading-2">
                                <strong>Similar Riders</strong>
                            </h4>
                            <div style={{overflow: "auto", alignContent: "center"}}>
                                <TableSimilar
                                    data={similarRiders}
                                    className="custom-table"
                                    style={{color: 'white', paddingRight: '10px'}}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>


                <Container>
                    <h4 className="project-heading-2">
                        <strong>Teams & Results</strong>
                    </h4>
                    <Row style={{justifyContent: "center", padding: "20px"}}>

                        <Col md={6} sm={6}>
                            <h4 className="project-heading-2">
                                <strong>Teams</strong>
                            </h4>
                            <div style={{overflow: "auto", alignContent: "center"}}>
                                <TableTeams
                                    data={queryResult3}
                                    className="custom-table"
                                    style={{color: "white", padding: "20px"}}
                                />
                            </div>
                        </Col>

                        <Col md={6} sm={6}>
                            <h4 className="project-heading-2">
                                <strong>Results</strong>
                            </h4>
                            <div style={{overflow: "auto", alignContent: "center"}}>
                                <TableResults
                                    data={queryResult6}
                                    className="custom-table"
                                    style={{color: "white", padding: "20px"}}
                                />
                            </div>
                        </Col>


                    </Row>
                </Container>


            </Container>


        </Container>
    )
        ;
};

function retrieveCountryCode(country) {
    switch (country) {
        case "US":
            return "US";
        case "UK":
            return "GB";
        case "Switzerland":
            return "CH";
        case "Great Britain":
            return "GB";
        case "Germany":
            return "DE";
        case "France":
            return "FR";
        case "Italy":
            return "IT";
        case "Spain":
            return "ES";
        case "Netherlands":
            return "NL";
        case "Belgium":
            return "BE";
        case "Austria":
            return "AT";
        case "Portugal":
            return "PT";
        case "Finland":
            return "FI";
        case "Ireland":
            return "IE";
        case "Greece":
            return "GR";
        case "Luxembourg":
            return "LU";
        case "Slovenia":
            return "SI";
        case "Slovakia":
            return "SK";
        case "Estonia":
            return "EE";
        case "Singapore":
            return "SG";
        case "Canada":
            return "CA";
        case "Australia":
            return "AU";
        case "New Zealand":
            return "NZ";
        case "Japan":
            return "JP";
        case "Denmark":
            return "DK";
        case "Sweden":
            return "SE";
        case "Argentina":
            return "AR";
        case "Czech Republic":
            return "CZ";
        case "Korea":
            return "KR";
        case "Norway":
            return "NO";
        case "Poland":
            return "PL";
        case "Hungary":
            return "HU";
        case "Romania":
            return "RO";
        case "Russia":
            return "RU";
        case "Turkey":
            return "TR";
        case "Brazil":
            return "BR";
        case "Mexico":
            return "MX";
        case "South Africa":
            return "ZA";
        case "China":
            return "CN";
        case "India":
            return "IN";
        case "Ecudor":
            return "EC";
        case "Colombia":
            return "CO";
        case "Chile":
            return "CL";
        case "Eritrea":
            return "ER";
        case "Egypt":
            return "EG";
        case "Morocco":
            return "MA";
        case "Tunisia":
            return "TN";
        case "USA":
            return "US";

        case "Israel":
            return "IL";
        case "Ukraine":
            return "UA";
        case "Kazakhstan":
            return "KZ";
        case "Latvia":
            return "LV";
        case "Lithuania":
            return "LT";
        case "Croatia":
            return "HR";
        case "Bulgaria":
            return "BG";
        case "Belarus":
            return "BY";
        case "Albania":
            return "AL";
        case "Andorra":
            return "AD";
        case "Armenia":
            return "AM";
        case "Azerbaijan":
            return "AZ";
        case "Bosnia and Herzegovina":
            return "BA";
        case "Cyprus":
            return "CY";
        case "Ecuador":
            return "EC";
        case "Thailand":
            return "TH";
        case "Iran":
            return "IR";
        case "United Arab Emirates":
            return "AE";


    }
}


export default RiderDetails;
