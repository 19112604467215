import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import RealmService from "./RealmService";
import CredentialsAPI from "../Credentials/CredentialsAPI";
import {useParams} from "react-router-dom";
import TableRace from "./TableRace";


const auctionsService = new RealmService('cycling-trbvl');

const Race = () => {
    const [queryResult, setQueryResult] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {id} = useParams();
    const [details, setDetails] = useState({});
    const [queryResult2, setQueryResult2] = useState([]);
    const [name, setName] = useState("");
    const [year, setYear] = useState("");

    useEffect(() => {
        const fetchData = async () => {

            await auctionsService.initialize('cycling-trbvl', CredentialsAPI.API_CYCLING);

            const mongodb = await auctionsService.getMongoClient();
            if (mongodb) {
                const collection = mongodb.db('Cyclists').collection('Races');
                let results = []
                let name = ""
                let year = 2023
                // Perform queries or operations on the collection

                // Example: Fetch all documents from the collection
                console.log(id)
                const documents = await collection.find({race_id: parseInt(id)})
                if (documents) {
                    const sorted_documents = documents.sort((a, b) => {
                        if (a.year < b.year) {
                            return 1;
                        } else if (a.year > b.year) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    results = sorted_documents[0].results;
                    name = sorted_documents[0].name;
                    year = sorted_documents[0].year;

                    setName(name);
                    setYear(year);

                    setQueryResult(results);


                }


            }
        };

        fetchData();
    }, []);


    return (
        <Container fluid className="project-section">
            <Container><h1 className="project-heading">
                <strong>{name} {year}</strong>
            </h1>


                <Row style={{justifyContent: "center", padding: "20px"}}>
                    <Col md={10} sm={10}>
                        <TableRace
                            data={queryResult}
                            className="custom-table"
                            style={{color: 'white', paddingRight: '10px'}}
                        />
                    </Col>
                </Row>

            </Container>
        </Container>
    );
};

export default Race;
