import React, {useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import {CgAwards} from "react-icons/cg";
import {ImBlog} from "react-icons/im";
import {AiFillHome,} from "react-icons/ai";
import {useMediaQuery} from "react-responsive";
import CredentialsAPI from "./Credentials/CredentialsAPI";
import Autosuggest from 'react-autosuggest';
import RealmService from "./Country/RealmService";
import {NavDropdown} from "react-bootstrap";


const auctionsService = new RealmService('cycling-trbvl');


function NavBar() {
    const isMobile = useMediaQuery({maxWidth: 600});
    const [expand, updateExpanded] = useState(false);
    const [navColour, updateNavbar] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getSuggestions = async (value) => {
        await auctionsService.initialize('cycling-trbvl', CredentialsAPI.API_CYCLING);

        const mongodb = await auctionsService.getMongoClient();
        if (mongodb) {
            const collection = mongodb.db('Cyclists').collection('Cyclists');

            // Perform queries or operations on the collection to get suggestions
            const documents = await collection.find({
                name: {$regex: value, $options: 'i'},
            });

            return documents.map((doc) => doc.name);
        }

        return [];
    };

    const handleSearch = (event, {newValue}) => {
        setSearchQuery(newValue);
    };

    const onSuggestionsFetchRequested = async ({value}) => {
        if (value.length >= 3) {
            setIsLoading(true);
            const suggestions = await getSuggestions(value);
            console.log(suggestions)
            setSuggestions(suggestions);
            setIsLoading(false);
        } else {
            setSuggestions([]);
        }
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const getSuggestionValue = (suggestion) => suggestion;

    const renderSuggestion = (suggestion) => {
        return (
            <div>
                <a href={`/riders/${suggestion}`}>{suggestion}</a>
            </div>
        );
    };

    const onSuggestionSelected = (event, {suggestion}) => {
        // Handle suggestion selection and navigation
        // You can use React Router's Link component to navigate to the corresponding page
        setSearchQuery(suggestion); // Update the searchQuery state to display the selected suggestion
    };

    const inputProps = {
        placeholder: 'Search',
        value: searchQuery,
        onChange: handleSearch,
    };

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true);
        } else {
            updateNavbar(false);
        }
    }

    window.addEventListener("scroll", scrollHandler);


    return (
        <Navbar
            expanded={expand}
            fixed="top"
            expand="md"
            className={navColour ? "sticky" : "navbar"}
        >
            <Container>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    onSuggestionSelected={onSuggestionSelected} // Handle suggestion selection
                    inputProps={inputProps}
                />

                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto" defaultActiveKey="#home">
                        <Nav.Item>
                            <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                                <AiFillHome style={{marginBottom: "2px"}}/> Home
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={Link} to="/teams" onClick={() => updateExpanded(false)}>
                                Teams
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <NavDropdown title={<span>Races</span>}
                                         id="books-dropdown">
                                <NavDropdown.Item
                                    as={Link} to="/race/13" onClick={() => updateExpanded(false)}>
                                    Giro d'Italia
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    as={Link} to="/race/17" onClick={() => updateExpanded(false)}>
                                    Tour de France
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    as={Link} to="/race/23" onClick={() => updateExpanded(false)}>
                                    Vuelta a España
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={Link} to="/last-year" onClick={() => updateExpanded(false)}>
                                Last Year Contract
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={Link} to="/predicted" onClick={() => updateExpanded(false)}>
                                Predicted stats
                            </Nav.Link>
                        </Nav.Item>


                        <Nav.Item>
                            <Nav.Link
                                href="https://finance.mrfreire.net/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <CgAwards style={{marginBottom: "2px"}}/> Finance
                            </Nav.Link>
                        </Nav.Item>


                        <Nav.Item>
                            <Nav.Link
                                href="https://mrfreire.net/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <ImBlog style={{marginBottom: "2px"}}/> Blog
                            </Nav.Link>
                        </Nav.Item>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;
