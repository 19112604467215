import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';

function BarChart({ data }) {
  const chartRef1 = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (chartRef1 && chartRef1.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
      const labels = data.map(item => item.season); // Extracting labels from the data array
      const values = data.map(item => item.points_per_distance); // Extracting values from the data array
      const values2 = data.map(item => item.wins_per_race); // Extracting values from the data array
      chartInstanceRef.current = new Chart(chartRef1.current, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Points Distance',
              data: values,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
                          {
              label: 'Wins Per Race',
              data: values2,
              backgroundColor: 'rgba(75, 192, 192, 0.4)',
              borderColor: 'rgba(75, 192, 192, 0.3)',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }
  }, [data]);

  return <canvas ref={chartRef1} />;
}

export default BarChart;
