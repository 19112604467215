import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';

function RadarChart({ data }) {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const labels = Object.keys(data);
      const values = Object.values(data);

      chartInstanceRef.current = new Chart(chartRef.current, {
        type: 'radar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Style',
              data: values,
              backgroundColor: 'rgba(75,192,192,0.4)',
              borderColor: 'rgba(75,192,192,1)',
              pointBackgroundColor: 'rgba(75,192,192,1)',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgba(75,192,192,1)',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            r: {
              pointLabels: {
                fontColor: 'transparent', // Hide numbers
              },
              beginAtZero: true,
            },
          },
        },
      });
    }
  }, [data]);

  return <canvas ref={chartRef} />;
}

export default RadarChart;
