import React, { useEffect, useState } from 'react';
import ReactCountryFlag from "react-country-flag";

function TableResults({ data }) {
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState('');
  const [rowsToShow, setRowsToShow] = useState(10); // Initial number of rows to show
  const rowsPerLoad = 10; // Number of rows to load on "Show More"

  useEffect(() => {
    setTableData(data);
  }, [data]);

  // ... (existing code)

  const handleShowMore = () => {
    setRowsToShow(prevRows => prevRows + rowsPerLoad);
  };

  // ... (existing code)
    const isMobile = window.innerWidth <= 768; // Example mobile width threshold

  const filteredData = data.sort((a, b) => b.season - a.season).slice(0, rowsToShow); // Slice the data array to display the required number of rows
const tableClassName = isMobile ? 'small' : 'big';
  // ... (existing code)

  return (
    <div style={{ padding: '10px' }}>
      <table className={tableClassName} style={{ color: 'white', margin: '0 auto' , fontSize: '11px' }}>
                      <thead>
            <tr>
                <th style={{padding: '20px'}}>Date</th>
                <th style={{padding: '20px'}}>Race</th>
                <th style={{padding: '20px', textAlign:"right"}}>Position</th>
            </tr>
            </thead>
        {/* ... (existing code) */}
        <tbody style={{ textAlign: 'center' }}>
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td>{item.Date}</td>
              <td>{item.Race}</td>
              <td>{item.Pos}</td>
            </tr>
          ))}
        </tbody>
        {/* ... (existing code) */}
      </table>
        <p></p>
      {rowsToShow < data.length && (
        <button style={{fontSize: '9px'}} onClick={handleShowMore}>Show More</button>
      )}
    </div>
  );
}

// ... (existing code)

export default TableResults;
