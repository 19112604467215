import React, {useEffect, useRef} from 'react';
import {Chart} from 'chart.js/auto';

function BarChart({data}) {
    const chartRef1 = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        if (chartRef1 && chartRef1.current) {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
            const labels = data.map(item => item.season); // Extracting labels from the data array
            const values = data.map(item => item.races); // Extracting values from the data array
            const values2 = data.map(item => item.wins); // Extracting values from the data array
            chartInstanceRef.current = new Chart(chartRef1.current, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Wins',
                            data: values2,
                            backgroundColor: 'rgba(0, 0, 139, 1)',
                            borderColor: 'rgba(0, 0, 139, 1)',
                            borderWidth: 1,
                            stacked: true
                        },
                        {
                            label: 'Races',
                            data: values,
                            backgroundColor: 'rgba(75, 192, 192, 0.8)',
                            borderColor: 'rgba(75, 192, 192, 0.9)',
                            borderWidth: 1,
                            stacked: true
                        },

                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            beginAtZero: true,
                            stacked: true
                        },
                        y: {
                            beginAtZero: true,
                        },
                    },
                },
            });
        }
    }, [data]);

    return <canvas ref={chartRef1}/>;
}

export default BarChart;
