import React, {useEffect, useRef, useState} from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import Table from "./Table";
import RealmService from "./RealmService";
import CredentialsAPI from "../Credentials/CredentialsAPI";
import TablePredicted from "./TablePredicted";


const auctionsService = new RealmService('cycling-trbvl');

const RidersPredicted = () => {
    const [queryResult, setQueryResult] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
      const containerRef = useRef(null);


  const handleScrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };
    useEffect(() => {
        const fetchData = async () => {

            await auctionsService.initialize('cycling-trbvl', CredentialsAPI.API_CYCLING);

            const mongodb = await auctionsService.getMongoClient();
            if (mongodb) {
                const collection = mongodb.db('Cyclists').collection('Predicted_Stats');

                // Perform queries or operations on the collection

                // Example: Fetch all documents from the collection
                const documents = await collection.find({'name': {'$exists': true}})
                console.log(documents)
                setIsLoading(false);
                setQueryResult(documents);
            }
        };

        fetchData();
    }, []);


    return (
    <Container fluid className="project-section">
          <Col md={2} sm={2}>
                    <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <button style={{fontSize: '9px'}} onClick={handleScrollToBottom}>Scroll to Bottom</button>
            </div>
              </Col>
      <Container>
        <Row style={{ justifyContent: 'center', paddingBottom: '10px' }}>
          <Col md={12} sm={6}>
            <div style={{ overflow: 'auto', alignContent: 'center' }} ref={containerRef}>
              {isLoading ? (
                // Show a loading spinner or loading indicator while data is being fetched
                <Spinner animation="border" role="status" color={'white'}>
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                // Render the table when the data is loaded
                <TablePredicted
                  data={queryResult}
                  className="custom-table"
                  style={{ color: 'white', paddingRight: '10px' }}
                />
              )}
            </div>

          </Col>
        </Row>
      </Container>
    </Container>
    );
};

export default RidersPredicted;
