import React, {useEffect, useState} from 'react';
import ReactCountryFlag from "react-country-flag";

function TableRace({data}) {
    const [tableData, setTableData] = useState([]);
    const [filter, setFilter] = useState('');


    useEffect(() => {
        setTableData(data);
    }, []);


    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = data

    const isMobile = window.innerWidth <= 768; // Example mobile width threshold

    const tableClassName = isMobile ? 'small' : 'big';


  return (
  <div style={{ padding: '40px' }}>
    <table className={tableClassName} style={{ color: 'white', margin: '0 auto', fontSize: '11px' }}>
      <thead>
        <tr>
          <th style={{ padding: '20px' }}>#</th>
          <th style={{ padding: '20px' }}>Name</th>
            <th style={{ padding: '20px' }}>Team</th>
            <th style={{ padding: '20px' }}>Points</th>
        </tr>
      </thead>
      <tbody style={{ textAlign: 'center' }}>
        {filteredData.map((item, index) => (
          <tr key={index}>
            <td style={{ padding: '20px' }}>{index + 1}</td>
            <td style={{ padding: '20px' }}>
              <a href={`/riders/${item.Rider}`}>{item.Rider}</a>
            </td>
            <td style={{ padding: '20px' }}>
              <a href={`/team/${item.Team}`}>{item.Team}</a>
            </td>
                          <td style={{ padding: '20px' }}>{item.UCI}</td>

          </tr>
        ))}
        {/* Total row */}
      </tbody>
      <style>
        {`
        @media (max-width: 600px) {
          table th:nth-child(3),
          table td:nth-child(3),
          table th:nth-child(4),
          table td:nth-child(4),
          table th:nth-child(5),
          table td:nth-child(5),
          table th:nth-child(6),
          table td:nth-child(6) {
            display: none;
          }
        }
      `}
      </style>
    </table>
  </div>
);
}


function retrieveCountryCode(country) {
    switch (country) {
        case "US":
            return "US";
        case "UK":
            return "GB";
        case "Switzerland":
            return "CH";
        case "Great Britain":
            return "GB";
        case "Germany":
            return "DE";
        case "France":
            return "FR";
        case "Italy":
            return "IT";
        case "Spain":
            return "ES";
        case "Netherlands":
            return "NL";
        case "Belgium":
            return "BE";
        case "Austria":
            return "AT";
        case "Portugal":
            return "PT";
        case "Finland":
            return "FI";
        case "Ireland":
            return "IE";
        case "Greece":
            return "GR";
        case "Luxembourg":
            return "LU";
        case "Slovenia":
            return "SI";
        case "Slovakia":
            return "SK";
        case "Estonia":
            return "EE";
        case "Singapore":
            return "SG";
        case "Canada":
            return "CA";
        case "Australia":
            return "AU";
        case "New Zealand":
            return "NZ";
        case "Japan":
            return "JP";
        case "Denmark":
            return "DK";
        case "Sweden":
            return "SE";
        case "Argentina":
            return "AR";
        case "Czech Republic":
            return "CZ";
        case "Korea":
            return "KR";
        case "Norway":
            return "NO";
        case "Poland":
            return "PL";
        case "Hungary":
            return "HU";
        case "Romania":
            return "RO";
        case "Russia":
            return "RU";
        case "Turkey":
            return "TR";
        case "Brazil":
            return "BR";
        case "Mexico":
            return "MX";
        case "South Africa":
            return "ZA";
        case "China":
            return "CN";
        case "India":
            return "IN";
        case "Ecuador":
            return "EC";
        case "Colombia":
            return "CO";
        case "Chile":
            return "CL";
        case "Eritrea":
            return "ER";
        case "Egypt":
            return "EG";
        case "Morocco":
            return "MA";
        case "Tunisia":
            return "TN";
        case "USA":
            return "US";

    }
}


export default TableRace;