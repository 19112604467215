import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faEquals } from '@fortawesome/free-solid-svg-icons';

function YourComponent({market_value_history}) {
    const lastIndex = market_value_history.length - 1;
    const lastItem = market_value_history[lastIndex];
    const prevItem = market_value_history[lastIndex - 1];
    let arrow = null;

  if (prevItem) {
    if (lastItem.value > prevItem.value) {
      arrow = <FontAwesomeIcon icon={faArrowUp} />;
    } else if (lastItem.value < prevItem.value) {
      arrow = <FontAwesomeIcon icon={faArrowDown} />;
    } else {
      arrow = <FontAwesomeIcon icon={faEquals} />;
    }
  }
    return (
        <div>
                {arrow && <div>{arrow}</div>}
        </div>
    );
}

export default YourComponent;
