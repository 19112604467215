import React, {useEffect, useState} from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Riders from "./components/MarketValue/Riders";
import RiderDetails from "./components/RiderDetails/RiderDetails";
import RidersTeam from "./components/TeamDetails/RidersTeam";
import RidersCountry from "./components/Country/RidersCountry";
import RidersLastYear from "./components/MarketValue/RidersLastYear";
import Teams from "./components/MarketValue/Teams";
import RidersPredicted from "./components/Predicted/RidersPredicted";
import Race from "./components/Races/Race";

function App() {
    const [load, upadateLoad] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            upadateLoad(false);
        }, 1200);

        return () => clearTimeout(timer);
    }, []);

    return (<Router>
        <Preloader load={load}/>
        <div className="App" id={load ? "no-scroll" : "scroll"}>
            <Navbar/>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<Riders/>}/>
                <Route path="*" element={<Navigate to="/"/>}/>
                <Route path="/riders/:name" element={<RiderDetails/>}/>
                <Route path="/team/:teamName" element={<RidersTeam/>}/>
                <Route path="/country/:land" element={<RidersCountry/>}/>
                <Route path="/last-year" element={<RidersLastYear/>}/>
                <Route path="/teams" element={<Teams/>}/>
                <Route path="/predicted" element={<RidersPredicted/>}/>
                <Route path="/race/:id" element={<Race/>}/>

            </Routes>
            <Footer/>
        </div>
    </Router>);
}

export default App;
