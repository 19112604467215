import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';

function BarChart({ data }) {
  const chartRef2 = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (chartRef2 && chartRef2.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
      const labels = data.map(item => item.date); // Extracting labels from the data array
      const values = data.map(item => item.value); // Extracting values from the data array
      chartInstanceRef.current = new Chart(chartRef2.current, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Market Value',
              data: values,
              backgroundColor: 'rgba(0, 0, 139, 1)',
              borderColor: 'rgba(0, 0, 139, 1)',
              borderWidth: 1,
            }
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }
  }, [data]);

  return <canvas ref={chartRef2} />;
}

export default BarChart;
