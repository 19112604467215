import React, {useEffect, useState} from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import Table from "./Table";
import RealmService from "./RealmService";
import CredentialsAPI from "../Credentials/CredentialsAPI";
import {useParams} from "react-router-dom";
import TableHistory from "./TableHistory";


const auctionsService = new RealmService('cycling-trbvl');

const RidersTeam = () => {
    const [queryResult, setQueryResult] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {teamName} = useParams();
    const [details, setDetails] = useState({});
    const [queryResult2, setQueryResult2] = useState([]);


    useEffect(() => {
        const fetchData = async () => {

            await auctionsService.initialize('cycling-trbvl', CredentialsAPI.API_CYCLING);

            const mongodb = await auctionsService.getMongoClient();
            if (mongodb) {
                const collection = mongodb.db('Cyclists').collection('Cyclists');
                const teams = mongodb.db('Cyclists').collection('Teams');

                // Perform queries or operations on the collection

                // Example: Fetch all documents from the collection
                const documents = await collection.find({team: (teamName)});
                if (documents) {
                    const team_id = documents[0].team_id
                    const documents2 = await teams.find({_id: (team_id)});

                    setIsLoading(false);
                    setQueryResult(documents);


                    if (documents2) {
                        const bike = documents2[0].bike;
                        const history = documents2[0].history;
                        setQueryResult2(history);

                        setDetails({
                            name: documents2[0].name,
                            bike: bike,
                            position: documents2[0].position,
                            wins: documents2[0].wins,
                            nationality: documents2[0].nationality


                        });
                    }
                }


            }
        };

        fetchData();
    }, []);


    return (
        <Container fluid className="project-section">
            <Container><h1 className="project-heading">
                <strong>Team {teamName}</strong>
            </h1>


                <Row style={{justifyContent: "center", padding: "20px"}}>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Position</h6>
                            <strong>{details.position}</strong>
                        </div>
                    </Col>

                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Nationality</h6>
                            <strong>{details.nationality}</strong>
                        </div>
                    </Col>

                                        <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Wins</h6>
                            <strong>{details.wins}</strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Bike</h6>
                            <strong>{details.bike}</strong>
                        </div>
                    </Col>
                </Row>



                <Row style={{justifyContent: 'center', paddingBottom: '10px'}}>
                    <Col md={10} sm={10}>
                        <div style={{overflow: 'auto', alignContent: 'center'}}>
                            {isLoading ? (
                                // Show a loading spinner or loading indicator while data is being fetched
                                <Spinner animation="border" role="status" color={"white"}>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            ) : (
                                // Render the table when the data is loaded
                                <Table
                                    data={queryResult}
                                    className="custom-table"
                                    style={{color: 'white', paddingRight: '10px', fontsize: '10px'}}
                                />
                            )}
                        </div>
                    </Col>
                                        <Col md={2} sm={2}>
                                <TableHistory
                                    data={queryResult2}
                                    className="custom-table"
                                    style={{color: 'white', paddingRight: '10px'}}
                                />
                    </Col>
                </Row>

            </Container>
        </Container>
    );
};

export default RidersTeam;
