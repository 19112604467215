import React, { useEffect, useRef } from 'react';
import { Chart, LineController, LinearScale, PointElement, Tooltip } from 'chart.js';

function BarChartDistance({ data }) {
  const chartRef1 = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (chartRef1 && chartRef1.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
      const labels = data.map(item => item.season); // Extracting labels from the data array
      const values = data.map(item => item.distance); // Use the original values without inverting
      chartInstanceRef.current = new Chart(chartRef1.current, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Distance',
              data: values,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            }
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
          tooltips: {
            callbacks: {
              label: (context) => {
                const originalValue = data[context.dataIndex].ranking;
                return `Ranking: ${originalValue}`;
              },
            },
          },
        },
      });
    }
  }, [data]);

  return <canvas ref={chartRef1} />;
}


export default BarChartDistance;
